import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import Header from "../../components/Header";

import styles from "./styles.module.less";

const Accessibility = () => {
    const header = <Header active={[]} theme="blue-ribbon" />;

    return (
        <Layout header={header}>
            <Helmet>
                <title>Accessibility Statement | Bay Equity Home Loans</title>
                <meta name="description" content="Accessibility Statement" />
                <meta name="keywords" content="accessibility statement" />
                <script
                    type="text/javascript"
                    src="https://bayequity.usablenet.com/pt/accessibilitystatement.js?l=1"
                    async></script>
            </Helmet>
            <div
                className={styles.accessibility}
                id="a40AccessibilityStatement"></div>
        </Layout>
    );
};

export default Accessibility;
